import React, { createContext, useContext, useEffect } from "react";
import LOGO from "assets/logo.png";
import { atom, useAtom } from "jotai";
import { Link, useLocation } from "react-router-dom";
import CSOB from "components/CSOB";
import { Spinner } from "@chakra-ui/react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { TangleConnection } from "TangleConnectionContext";
import { useSpectodaSound } from "lib/utils/utils";

export const headerAtom = atom("");

const pageMotion = {
  initial: { opacity: 0, x: 0, scale: 0.1 },
  animate: { opacity: 1, transition: { duration: 0.3 }, scale: 1 },
  exit: { opacity: 0, x: 0, transition: { duration: 0.3 }, scale: 0.1 },
};

export default function Header() {
  const [header, setHeader] = useAtom(headerAtom);
  const location = useLocation();
  const { isConnecting, connectionStatus, connect, disconnect } =
    useContext(TangleConnection);
  const status = isConnecting ? "connecting" : connectionStatus;

  useEffect(() => {
    const path = location.pathname;
    // console.log({ path });
    switch (path) {
      case "/":
        setHeader("Home");
        break;
      case "/mp3":
        setHeader("MP3");
        break;
      case "/mp3/default":
        setHeader("Simulace hlasu");
        break;
    }
  });

  return (
    <AnimatePresence exitBeforeEnter>
      <div className="pb-5 mb-4 h-20">
        {header !== "Home" ? (
          <CSOB.Container
            direction="row"
            className="justify-between items-center"
          >
            <CSOB.Title className="text-lg inline pl-0 mt-0">
              {header}
            </CSOB.Title>
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={pageMotion}
            >
              <div className="flex">
                <span
                  onClick={() =>
                    status === "connected" || status === "connecting"
                      ? disconnect()
                      : connect()
                  }
                >
                  <ConnectionIcon status={status} />
                </span>
                <Link to="/">
                  <motion.svg
                    whileTap={{
                      scale: 0.9,
                    }}
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="25" cy="25" r="25" fill="#003366" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.8333 17.3201C22.2088 17.7251 22.1849 18.3579 21.7799 18.7333L18.5495 21.7282H30.3641C33.4767 21.7282 36 24.2515 36 27.3641C36 30.4767 33.4767 33 30.3641 33H26.5C25.9477 33 25.5 32.5523 25.5 32C25.5 31.4477 25.9477 31 26.5 31H30.3641C32.3722 31 34 29.3722 34 27.3641C34 25.3561 32.3722 23.7282 30.3641 23.7282H18.5324L21.7832 26.7698C22.1865 27.1471 22.2075 27.7799 21.8302 28.1832C21.4529 28.5865 20.8201 28.6075 20.4168 28.2302L15.3168 23.4584C15.1141 23.2688 14.9994 23.0035 15 22.7259C15.0006 22.4484 15.1166 22.1836 15.3201 21.9949L20.4201 17.2667C20.8251 16.8912 21.4578 16.9151 21.8333 17.3201Z"
                      fill="white"
                    />
                  </motion.svg>
                </Link>
              </div>
            </motion.div>
          </CSOB.Container>
        ) : (
          <CSOB.Container>
            <div className="flex justify-between">
              <img src={LOGO} alt="" />
              <span
                onClick={() =>
                  status === "connected" || status === "connecting"
                    ? disconnect()
                    : connect()
                }
              >
                <ConnectionIcon status={status} />
              </span>
            </div>
          </CSOB.Container>
        )}
      </div>
    </AnimatePresence>
  );
}

// const AppProvider = createContext({ title });

// function ({ children }) {
//   return <HeaderAtom.Provider value={headerAtom}>{children}</HeaderAtom.Provider>;
// }

export function ConnectionIcon({
  status,
}: {
  status: "connected" | "connecting" | "disconnected";
}) {
  return (
    <div
      className={classNames(
        "w-[50px] h-[50px] rounded-full flex justify-center items-center mr-5 transition-colors duration-500",
        status === "connected" ? "bg-[#0099CC]" : "bg-[#FF6000]"
      )}
    >
      {status === "connected" ? (
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.125 21.4122C24.125 20.929 24.5168 20.5372 25 20.5372C25.4832 20.5372 25.875 20.929 25.875 21.4122V35.4122C25.875 35.8955 25.4832 36.2872 25 36.2872C24.5168 36.2872 24.125 35.8955 24.125 35.4122V21.4122ZM21.498 25.3301C21.175 25.6892 20.6166 25.7229 20.3059 25.3532C19.9408 24.9188 19.6369 24.4347 19.404 23.9143C19.0517 23.1272 18.8696 22.2745 18.8696 21.4122C18.8696 20.5499 19.0517 19.6973 19.404 18.9102C19.6369 18.3897 19.9408 17.9056 20.3059 17.4712C20.6166 17.1015 21.175 17.1352 21.498 17.4943C21.8225 17.8552 21.7833 18.4072 21.4931 18.7962C21.3016 19.0528 21.1384 19.3304 21.0068 19.6242C20.755 20.1867 20.6248 20.796 20.6248 21.4122C20.6248 22.0285 20.755 22.6378 21.0068 23.2002C21.1384 23.4941 21.3016 23.7716 21.4931 24.0283C21.7833 24.4173 21.8225 24.9692 21.498 25.3301ZM29.6941 25.3532C29.3834 25.7229 28.825 25.6892 28.502 25.3301C28.1775 24.9692 28.2167 24.4173 28.5069 24.0283C28.6984 23.7716 28.8616 23.4941 28.9932 23.2002C29.245 22.6378 29.3752 22.0285 29.3752 21.4122C29.3752 20.796 29.245 20.1867 28.9932 19.6242C28.8616 19.3304 28.6984 19.0528 28.5069 18.7962C28.2167 18.4072 28.1775 17.8552 28.502 17.4943C28.825 17.1352 29.3834 17.1015 29.6941 17.4712C30.0592 17.9056 30.3631 18.3897 30.596 18.9102C30.9483 19.6973 31.1304 20.5499 31.1304 21.4122C31.1304 22.2745 30.9483 23.1272 30.596 23.9143C30.3631 24.4347 30.0592 24.9188 29.6941 25.3532Z"
            fill="white"
          />
          <path
            d="M18.875 28.4124C18.5851 28.799 18.0335 28.8802 17.6748 28.5563C16.7865 27.7543 16.0536 26.7918 15.5161 25.7168C14.8479 24.3804 14.5 22.9066 14.5 21.4124C14.5 19.9182 14.8479 18.4445 15.5161 17.108C16.0536 16.033 16.7865 15.0705 17.6748 14.2685C18.0335 13.9446 18.5851 14.0258 18.875 14.4124C19.1649 14.799 19.0826 15.3442 18.7306 15.6752C18.0563 16.3092 17.4973 17.0587 17.0814 17.8906C16.5346 18.9841 16.25 20.1898 16.25 21.4124C16.25 22.635 16.5346 23.8407 17.0814 24.9342C17.4973 25.7661 18.0563 26.5156 18.7306 27.1496C19.0826 27.4807 19.1649 28.0258 18.875 28.4124ZM32.3252 28.5563C31.9665 28.8802 31.4149 28.799 31.125 28.4124C30.8351 28.0258 30.9174 27.4807 31.2694 27.1496C31.9437 26.5156 32.5027 25.7661 32.9186 24.9342C33.4654 23.8407 33.75 22.635 33.75 21.4124C33.75 20.1898 33.4654 18.9841 32.9186 17.8906C32.5027 17.0587 31.9437 16.3092 31.2694 15.6752C30.9174 15.3442 30.8351 14.799 31.125 14.4124C31.4149 14.0258 31.9665 13.9446 32.3252 14.2685C33.2135 15.0705 33.9464 16.033 34.4839 17.108C35.1521 18.4445 35.5 19.9182 35.5 21.4124C35.5 22.9066 35.1521 24.3804 34.4839 25.7168C33.9464 26.7918 33.2135 27.7543 32.3252 28.5563Z"
            fill="white"
          />
        </svg>
      ) : status === "connecting" ? (
        <Spinner color="white" />
      ) : (
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.125 21.4122C24.125 20.929 24.5168 20.5372 25 20.5372C25.4832 20.5372 25.875 20.929 25.875 21.4122V35.4122C25.875 35.8955 25.4832 36.2872 25 36.2872C24.5168 36.2872 24.125 35.8955 24.125 35.4122V21.4122ZM21.498 25.3301C21.175 25.6892 20.6166 25.7229 20.3059 25.3532C19.9408 24.9188 19.6369 24.4347 19.404 23.9143C19.0517 23.1272 18.8696 22.2745 18.8696 21.4122C18.8696 20.5499 19.0517 19.6973 19.404 18.9102C19.6369 18.3897 19.9408 17.9056 20.3059 17.4712C20.6166 17.1015 21.175 17.1352 21.498 17.4943C21.8225 17.8552 21.7833 18.4072 21.4931 18.7962C21.3016 19.0528 21.1384 19.3304 21.0068 19.6242C20.755 20.1867 20.6248 20.796 20.6248 21.4122C20.6248 22.0285 20.755 22.6378 21.0068 23.2002C21.1384 23.4941 21.3016 23.7716 21.4931 24.0283C21.7833 24.4173 21.8225 24.9692 21.498 25.3301ZM29.6941 25.3532C29.3834 25.7229 28.825 25.6892 28.502 25.3301C28.1775 24.9692 28.2167 24.4173 28.5069 24.0283C28.6984 23.7716 28.8616 23.4941 28.9932 23.2002C29.245 22.6378 29.3752 22.0285 29.3752 21.4122C29.3752 20.796 29.245 20.1867 28.9932 19.6242C28.8616 19.3304 28.6984 19.0528 28.5069 18.7962C28.2167 18.4072 28.1775 17.8552 28.502 17.4943C28.825 17.1352 29.3834 17.1015 29.6941 17.4712C30.0592 17.9056 30.3631 18.3897 30.596 18.9102C30.9483 19.6973 31.1304 20.5499 31.1304 21.4122C31.1304 22.2745 30.9483 23.1272 30.596 23.9143C30.3631 24.4347 30.0592 24.9188 29.6941 25.3532Z"
            fill="white"
          />
          <path
            d="M18.875 28.4124C18.5851 28.799 18.0335 28.8802 17.6748 28.5563C16.7865 27.7543 16.0536 26.7918 15.5161 25.7168C14.8479 24.3804 14.5 22.9066 14.5 21.4124C14.5 19.9182 14.8479 18.4445 15.5161 17.108C16.0536 16.033 16.7865 15.0705 17.6748 14.2685C18.0335 13.9446 18.5851 14.0258 18.875 14.4124C19.1649 14.799 19.0826 15.3442 18.7306 15.6752C18.0563 16.3092 17.4973 17.0587 17.0814 17.8906C16.5346 18.9841 16.25 20.1898 16.25 21.4124C16.25 22.635 16.5346 23.8407 17.0814 24.9342C17.4973 25.7661 18.0563 26.5156 18.7306 27.1496C19.0826 27.4807 19.1649 28.0258 18.875 28.4124ZM32.3252 28.5563C31.9665 28.8802 31.4149 28.799 31.125 28.4124C30.8351 28.0258 30.9174 27.4807 31.2694 27.1496C31.9437 26.5156 32.5027 25.7661 32.9186 24.9342C33.4654 23.8407 33.75 22.635 33.75 21.4124C33.75 20.1898 33.4654 18.9841 32.9186 17.8906C32.5027 17.0587 31.9437 16.3092 31.2694 15.6752C30.9174 15.3442 30.8351 14.799 31.125 14.4124C31.4149 14.0258 31.9665 13.9446 32.3252 14.2685C33.2135 15.0705 33.9464 16.033 34.4839 17.108C35.1521 18.4445 35.5 19.9182 35.5 21.4124C35.5 22.9066 35.1521 24.3804 34.4839 25.7168C33.9464 26.7918 33.2135 27.7543 32.3252 28.5563Z"
            fill="white"
          />
          <path
            d="M16.1101 12.1528L34.6289 30.6716"
            stroke="white"
            strokeWidth="1.8"
            strokeLinecap="round"
          />
        </svg>
      )}
    </div>
  );
}
