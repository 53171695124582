import { atom, useAtom } from "jotai";
import Header from "layout/Header";
import { tangleDevice } from "lib/utils/communication";
import { useSpectodaSound } from "lib/utils/utils";
import React, { useEffect, useContext } from "react";
import { TangleConnection } from "TangleConnectionContext";
import CSOB from "../components/CSOB";
import { micSensitivityAtom } from "./MP3";

// create jotai atom which stores the current animation
export const activeAtom = atom("off");

export default function Home() {
  const [active, setActive] = useAtom(activeAtom);
  const [microphoneSensitivity, setMicrophoneSensitivity] =
    useAtom(micSensitivityAtom);
  const { start, stop, spectodaSound } = useSpectodaSound();
  const { connectionStatus, connect } = useContext(TangleConnection);

  async function emitMode(mode: string) {
    if (connectionStatus === "connected") {
      tangleDevice.emitPercentageEvent("reset", 0);
      tangleDevice.emitPercentageEvent("e_" + mode, 100);
      setActive(mode);
    }
  }

  useEffect(() => {
    emitMode("off");
    stop();
    return () => stop();
  }, []);

  useEffect(() => {
    if (active === "reaction") {
      start();
    } else {
      stop();
    }
  }, [active]);

  useEffect(() => {
    const ref = spectodaSound.on("loudness", (value: number) => {
      tangleDevice.emitPercentageEvent("sound", value);
    });
    return () => ref();
  }, [spectodaSound]);

  useEffect(() => {
    spectodaSound.setSensitivity(microphoneSensitivity);
  }, [microphoneSensitivity]);

  return (
    <CSOB.HomeLayoutAnimationWrapper
    // onClick={() => connectionStatus === "disconnected" && connect()}
    >
      <CSOB.Box>
        <CSOB.Title>Vyberte režim Kate</CSOB.Title>
        <CSOB.Container>
          <CSOB.Button
            onClick={() => emitMode("off")}
            className={CSOB.getButtonClass(active === "off")}
          >
            Vypnuto
          </CSOB.Button>
          <CSOB.Button
            onClick={() => emitMode("solid")}
            className={CSOB.getButtonClass(active === "solid")}
          >
            Solidní barva
          </CSOB.Button>
          {/* <CSOB.Button
            onClick={() => emitMode("simulation")}
            className={CSOB.getButtonClass(active === "simulation")}
          >
            Simulace animace hlasu
          </CSOB.Button> */}
          <div>
            <CSOB.Link
              className={CSOB.getButtonClass(false) + " text-center "}
              to="mp3/default"
            >
              Simulace animace hlasu
            </CSOB.Link>
          </div>
          <CSOB.Button
            onClick={() => emitMode("reaction")}
            className={CSOB.getButtonClass(active === "reaction")}
          >
            Reagovat na zvuky
          </CSOB.Button>
          {/*  className={connectionStatus === "connected" ? "" : "pointer-events-none"} */}
          <div>
            <CSOB.Link
              className={CSOB.getButtonClass(false) + " text-center "}
              to="mp3"
            >
              Nahrát vlastní MP3
            </CSOB.Link>
          </div>
        </CSOB.Container>
      </CSOB.Box>

      <div className="mt-4"></div>
      {active === "reaction" && (
        <CSOB.Box>
          <CSOB.Container>
            <CSOB.Title>Sensitivita</CSOB.Title>

            <input
              className="border border-black p-1 m-2 rounded-md  max-w-full"
              value={microphoneSensitivity}
              onChange={(e: any) =>
                setMicrophoneSensitivity(e.target.valueAsNumber)
              }
              type="range"
              step="5"
              min="5"
              max="250"
            />
          </CSOB.Container>
        </CSOB.Box>
      )}
    </CSOB.HomeLayoutAnimationWrapper>
  );
}
