import { useAtom } from "jotai";
import { tangleDevice } from "lib/utils/communication";
import { atomWithLocalStorage, useSpectodaSound } from "lib/utils/utils";
import React, { useEffect, useState } from "react";
import { AudioPlayerProvider } from "react-use-audio-player";
import CSOB from "../components/CSOB";
import { activeAtom } from "./Home";
import { PlayerComponent } from "./PlayerComponent";

import { getFirestore, doc, getDoc, FieldValue, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject, getBlob } from "firebase/storage";
import { db, storage } from "lib/utils/firebase";
import { remove, set, update } from "firebase/database";
import { TangleMsgBox } from "lib/webcomponents/dialog-component";
export const micSensitivityAtom = atomWithLocalStorage("micSensitivityAtom", 100);

export default function MP3() {
  const [microphoneSensitivity, setMicrophoneSensitivity] = useAtom(micSensitivityAtom);

  const { start, stop, spectodaSound } = useSpectodaSound();
  const [, setActive] = useAtom(activeAtom);

  const [files, setFiles] = useState<any[]>([]);
  const [progress, setProgress] = useState<number | "failed">(0);
  const [isLoading, setIsLoading] = useState(true);

  async function emitMode(mode: string) {
    tangleDevice.emitPercentageEvent("reset", 0);
    tangleDevice.emitPercentageEvent("e_" + mode, 100);
    setActive(mode);
  }

  const docRef = doc(db, "custom/csobmp3");

  useEffect(() => {
    emitMode("reaction-mp3");
    stop();

    const fetchMP3s = async () => {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        // @ts-ignore
        const mp3s = data.mp3s.map(name => ({ name }));

        // Load each MP3 file from Firebase Storage
        const storage = getStorage();
        const promises = mp3s.map(async (fileObj: any) => {
          const fileRef = ref(storage, "csobmp3/" + fileObj.name);
          const blob = await getBlob(fileRef);
          // const response = await fetch(url);
          // const blob = await response.blob();
          const file = new File([blob], fileObj.name, { type: "audio/mp3" });
          fileObj.url = URL.createObjectURL(file);
          return fileObj;
        });

        Promise.all(promises)
          .then(files => {
            setFiles(files);
            setIsLoading(false);
          })
          .catch(error => {
            console.error("Error loading MP3 files:", error);
          });
      } else {
        console.log("No such document!");
      }
    };

    fetchMP3s();

    return () => stop();
  }, []);

  useEffect(() => {
    emitMode("reaction-mp3");
    stop();
    return () => stop();
  }, []);

  function addFile() {
    let selectFile = document.createElement("INPUT");
    selectFile.setAttribute("accept", "audio/*");
    // @ts-ignore
    selectFile.type = "file";
    selectFile.addEventListener(
      "change",
      async ({ target }) => {
        // @ts-ignore
        const file = target.files[0] as File;
        const filename = file.name;

        // Create a storage reference for the MP3 file
        const fileRef = ref(storage, "csobmp3/" + filename);

        // Upload the file to Firebase Storage
        const uploadTask = uploadBytesResumable(fileRef, file);

        // Track the upload progress
        uploadTask.on(
          "state_changed",
          snapshot => {
            // Calculate the upload progress
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload progress: " + progress + "%");
            setProgress(progress);
            // Update the progress variable or perform any other action
            // with the progress value here
          },
          error => {
            console.log("Upload failed:", error);
            setProgress("failed");
          },
          () => {
            // doc(db, "custom/csobmp3" + filename) add to store documentarray

            const data = {
              mp3s: arrayUnion(filename),
            };
            updateDoc(docRef, data);

            getBlob(uploadTask.snapshot.ref).then(blob => {
              setProgress(0);

              const file = new File([blob], filename, { type: "audio/mp3" });
              let url = URL.createObjectURL(file);
              // Add the file and URL to local state
              setFiles(files => [...files, { name: filename, url }]);
            });
            // const response = await fetch(url);

            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {});
          },
        );
      },
      false,
    );
    selectFile.click();
  }

  async function removeFile(i: any) {
    const { name } = files[i];

    // @ts-ignore
    if (!(await TangleMsgBox.confirm("Opravdu chcete smazat soubor: " + name + "?", "", { confirm: "Potvrdit", cancel: "Zrušit" }))) {
      return;
    }

    // Delete file from Firebase Storage
    const storage = getStorage();
    const fileRef = ref(storage, "csobmp3/" + name);
    console.log({ name });
    const data = {
      mp3s: arrayRemove(name),
    };
    updateDoc(docRef, data);
    await deleteObject(fileRef);

    // Remove from local state
    setFiles(files.filter((_, index) => index !== i));
  }

  return (
    <CSOB.LayoutAnimationWrapper>
      <CSOB.Box>
        <CSOB.Container>
          <CSOB.Title>Sensitivita</CSOB.Title>

          <input className="border border-black p-1 m-2 rounded-md  max-w-full" value={microphoneSensitivity} onChange={(e: any) => setMicrophoneSensitivity(e.target.valueAsNumber)} type="range" step="5" min="5" max="250" />
        </CSOB.Container>
      </CSOB.Box>
      <div className="mt-4"></div>
      {isLoading && <p className="font-bold">{"Načítám mp3..."}</p>}
      {files.map(({ name, url }, index) => (
        <React.Fragment key={name}>
          <div className="mt-4"></div>
          <CSOB.Box>
            <CSOB.Container>
              <div className="flex justify-end ">
                <button onClick={() => removeFile(index)} className="font-bold text-lg pr-1">
                  X
                </button>
              </div>

              <CSOB.Button>{name ? name : "Unknown"}</CSOB.Button>
            </CSOB.Container>
            <CSOB.Container>
              <AudioPlayerProvider>{url && <PlayerComponent emitMode={emitMode} microphoneSensitivity={microphoneSensitivity} url={url} name={name} />}</AudioPlayerProvider>
            </CSOB.Container>
          </CSOB.Box>
        </React.Fragment>
      ))}

      <CSOB.Container>{!isLoading && files.length === 0 && <p className="font-bold">{"Žádné MP3 nenalezeny."}</p>}</CSOB.Container>

      {(progress === 0 || progress === "failed") && <CSOB.Button onClick={addFile}>{"Nahrát MP3"}</CSOB.Button>}

      {progress !== 0 &&
        (progress !== "failed" ? (
          <CSOB.Container>
            <CSOB.Box>
              <CSOB.Title>Nahrávání mp3</CSOB.Title>
              <div className="w-full h-2 bg-[rgba(37,99,235,0.2)] mt-3">
                <div className="h-2 bg-blue-600" style={{ width: progress + "%" }}></div>
              </div>
            </CSOB.Box>
          </CSOB.Container>
        ) : (
          <CSOB.Container>
            <CSOB.Box>
              <CSOB.Title>Nahrávání selhalo</CSOB.Title>
            </CSOB.Box>
          </CSOB.Container>
        ))}
      <div className="mt-4"></div>
    </CSOB.LayoutAnimationWrapper>
  );
}
