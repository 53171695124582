import { useAtom } from "jotai";
import { tangleDevice } from "lib/utils/communication";
import { atomWithLocalStorage, useSpectodaSound } from "lib/utils/utils";
import React, { useEffect, useState } from "react";
import { AudioPlayerProvider } from "react-use-audio-player";
import CSOB from "../components/CSOB";
import { activeAtom } from "./Home";
import { micSensitivityAtom } from "./MP3";
import { PlayerComponent } from "./PlayerComponent";

export default function MP3Default() {
  const [microphoneSensitivity, setMicrophoneSensitivity] =
    useAtom(micSensitivityAtom);

  const { start, stop, spectodaSound } = useSpectodaSound();
  const [active, setActive] = useAtom(activeAtom);
  const [file, setFile] = useState<any>(false);
  const [fileurl, setFileUrl] = useState<any>(false);

  function loadFile() {
    // Create once invisible browse button with event listener, and click it
    let selectFile = document.createElement("INPUT");
    selectFile.setAttribute("accept", "audio/*");
    // @ts-ignore
    selectFile.type = "file";
    // @ts-ignore
    selectFile.addEventListener(
      "change",
      ({ target }) => {
        // @ts-ignore
        const file = target.files[0] as File;
        const filename = file.name;
        setFile(file);
        const url = URL.createObjectURL(file);
        setFileUrl(url);

        // @ts-ignore
        window.music = file;
      },
      false
    );
    selectFile.click();
  }

  async function emitMode(mode: string) {
    tangleDevice.emitPercentageEvent("reset", 0);
    tangleDevice.emitPercentageEvent("e_" + mode, 100);
    setActive(mode);
  }

  useEffect(() => {
    emitMode("reaction-mp3");
    stop();
    return () => stop();
  }, []);

  return (
    <CSOB.LayoutAnimationWrapper>
      <CSOB.Box>
        <CSOB.Container>
          {/* <CSOB.Title>Simulace hlasu</CSOB.Title> */}
          {/* <CSOB.Button onClick={loadFile}>
            {file.name ? file.name : "Vybrat Soubor"}
          </CSOB.Button> */}
          {/* <CSOB.Title>{file.name}</CSOB.Title> */}
        </CSOB.Container>
        <CSOB.Container>
          <AudioPlayerProvider>
            <PlayerComponent
              emitMode={emitMode}
              microphoneSensitivity={microphoneSensitivity}
              file={"/kate_simulace.wav"}
              name={""}
            />
          </AudioPlayerProvider>
        </CSOB.Container>
      </CSOB.Box>
      <div className="mt-4"></div>
      <CSOB.Box>
        <CSOB.Container>
          <CSOB.Title>Sensitivita</CSOB.Title>

          <input
            className="border border-black p-1 m-2 rounded-md  max-w-full"
            value={microphoneSensitivity}
            onChange={(e: any) =>
              setMicrophoneSensitivity(e.target.valueAsNumber)
            }
            type="range"
            step="5"
            min="5"
            max="250"
          />
        </CSOB.Container>
      </CSOB.Box>
    </CSOB.LayoutAnimationWrapper>
  );
}
