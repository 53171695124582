import React, { useEffect } from "react";
import CSOB from "./components/CSOB";
import { HashRouter as BrowserRouter } from "react-router-dom";
import Routing from "./Routing";
import { ChakraProvider } from "@chakra-ui/react";
import { TangleMsgBox } from "lib/webcomponents/dialog-component";
import { TangleConnectionProvider } from "TangleConnectionContext";

function App() {
  useEffect(() => {
    (async () => {
      if (localStorage.getItem("app_access_token") !== "0808") {
        let token;
        token = await TangleMsgBox.prompt(
          "",
          "",
          "Zadejte kód",
          "",
          // @ts-ignore
          {},
          { confirm: "Potvrdit", cancel: "null" }
        );
        while (token.toUpperCase() !== "0808") {
          await TangleMsgBox.alert("", "Kód není správný");
          token = await TangleMsgBox.prompt(
            "",
            "",
            "Zadejte kód",
            "",
            // @ts-ignore
            {},
            { confirm: "Potvrdit", cancel: "null" }
          );
        }
        localStorage.setItem("app_access_token", token.toUpperCase());
      }
    })();
  }, []);

  return (
    <div className="mx-auto mt-5 max-w-md select-none">
      <ChakraProvider>
        <BrowserRouter>
          <TangleConnectionProvider>
            <Routing />
          </TangleConnectionProvider>
        </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}

export default App;
