import Firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getDatabase } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCnAfdv909gThutnCKwsoiNaSrHbqRUJv0",
  authDomain: "tangle-49512.firebaseapp.com",
  databaseURL: "https://tangle-49512-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "tangle-49512",
  storageBucket: "tangle-49512.appspot.com",
  messagingSenderId: "707203240184",
  appId: "1:707203240184:web:d634fdd4b4c5fa2379eb0d",
  measurementId: "G-EJCQSBZ6YJ",
};
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
const app = Firebase.initializeApp(firebaseConfig);

export const db = Firebase.firestore();

export const storage = Firebase.storage();

export const realtimeDB = getDatabase(app);

export const firebase = Firebase;

const analytics = typeof window !== "undefined" && getAnalytics();

export const auth = getAuth();
auth.languageCode = "cs";
