import React, { FC } from "react";
import { motion } from "framer-motion";
import { Link as RLink, LinkProps } from "react-router-dom";
import classNames from "classnames";
import { useCSOBStore } from "CsobDataStore";
interface GeneralProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

function getButtonClass(isRunning: boolean) {
  return classNames(
    "justify-center transition-colors duration-200 ease-in-out",
    isRunning && "text-white bg-dark"
  );
}

const Button: FC<GeneralProps> = ({ children, onClick, className }) => {
  return (
    <motion.div
      className={classNames(
        "flex items-center px-4 py-3 border border-transparent text-base  rounded-lg  bg-[#F5F9FC]  focus:outline-none focus:ring-2 focus:ring-offset-2  mt-3 font-bold cursor-pointer",
        className
      )}
      whileTap={{
        scale: 0.95,
      }}
      onClick={onClick}
    >
      {children}
    </motion.div>
  );
};

const pageMotion = {
  initial: { opacity: 0, x: 0, y: -100 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.15 } },
  exit: { opacity: 0, y: 100, x: 0, transition: { duration: 0.15 } },
};

const LayoutAnimationWrapper: FC<GeneralProps> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
    >
      {children}
    </motion.div>
  );
};

// const homepageMotion = {
//   initial: { opacity: 0, x: 0, y: 100 },
//   animate: { opacity: 1, y: 0, transition: { duration: 0.15 } },
//   exit: { opacity: 0, y: -100, x: 0, transition: { duration: 0.15 } },
// };

const HomeLayoutAnimationWrapper: FC<GeneralProps> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
      onClick={onClick}
    >
      {children}
    </motion.div>
  );
};

const Box = ({ children }: React.PropsWithChildren<any>) => {
  return (
    <motion.div
      className="bg-white  rounded-2xl overflow-hidden"
      style={{ boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)" }}
    >
      {children}
    </motion.div>
  );
};

const Container: FC<any> = ({ children, onClick, className, direction }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        `flex ${direction === "row" ? "flex-row" : "flex-col"} p-3`,
        className
      )}
    >
      {children}
    </div>
  );
};

const Title = ({
  children,
  className,
}: React.PropsWithChildren<GeneralProps>) => {
  return (
    <h1 className={classNames("text-md font-bold pl-4 mt-4", className)}>
      {children}
    </h1>
  );
};

const Link = (props: React.PropsWithChildren<LinkProps>) => {
  // return <RLink {...props}  />;
  return (
    <RLink to={props.to} className={classNames("block")}>
      <Button className={classNames(props.className)}>{props.children}</Button>
    </RLink>
  );
};

interface AnimationButtonProps {
  text: string;
  event: string;
}

const AnimationBox = ({
  title,
  button,
}: React.PropsWithChildren<{
  title: string;
  button: AnimationButtonProps;
}>) => {
  const [animations, setAnimation] = useCSOBStore((state) => [
    state.animations,
    state.setAnimation,
  ]);

  // @ts-ignore
  const isRunning = animations[button.event] === true;

  function setIsRunning(value: boolean) {
    // @ts-ignore
    setAnimation(button.event, value);
  }

  let buttontext = isRunning
    ? button.text.replace("Spustit", "Zastavit")
    : button.text;
  buttontext = isRunning ? buttontext.replace("Otevřít", "Zavřít") : buttontext;

  return (
    <motion.div
      className="bg-white rounded-2xl overflow-hidden mt-4"
      style={{ boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)" }}
    >
      <Title>{title}</Title>
      <Container>
        <Button
          className={classNames(
            "mt-0 justify-center transition-colors duration-200 ease-in-out",
            isRunning && "text-white bg-[#003366]"
          )}
          onClick={() => setIsRunning(!isRunning)}
        >
          {buttontext}
        </Button>
      </Container>
    </motion.div>
  );
};

const MultiAnimationBox = ({
  title,
  buttons,
}: React.PropsWithChildren<{
  title: string;
  buttons: AnimationButtonProps[];
}>) => {
  const [animations, setAnimation] = useCSOBStore((state) => [
    state.animations,
    state.setAnimation,
  ]);

  function setIsRunning(value: string) {
    // @ts-ignore
    setAnimation(title, value);
  }

  return (
    <motion.div
      className="bg-white rounded-2xl overflow-hidden mt-4"
      style={{ boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)" }}
    >
      <Title>{title}</Title>
      <Container>
        {buttons.map((button) => {
          // @ts-ignore
          const isRunning = animations[title] === button.event;

          return (
            <Button
              key={button.event}
              className={classNames(
                "mt-3 justify-center transition-colors duration-200 ease-in-out",
                isRunning && "text-white bg-[#003366]"
              )}
              onClick={() => setIsRunning(button.event)}
            >
              {button.text}
            </Button>
          );
        })}
      </Container>
    </motion.div>
  );
};

const CSOB = {
  Button,
  Container,
  Title,
  Box,
  Link,
  AnimationBox,
  LayoutAnimationWrapper,
  HomeLayoutAnimationWrapper,
  MultiAnimationBox,
  getButtonClass,
};

export default CSOB;
