import { atom } from "jotai";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TangleConnection } from "../../TangleConnectionContext";
import { SpectodaSound } from "../tangle-js/SpectodaSound";
import { tangleDevice } from "./communication";

export const atomWithLocalStorage = (key: string, initialValue: any) => {
  const getInitialValue = () => {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item);
    }
    return initialValue;
  };
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue = typeof update === "function" ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      localStorage.setItem(key, JSON.stringify(nextValue));
    }
  );
  return derivedAtom;
};

// export const AlwaysActiveRoute = ({
//   path,
//   element,
// }: {
//   path: string;
//   element: React.Component;
// }) => {
//   const location = useLocation();

//   console.log({ location });

//   return <div className={location.pathname === path ? "block" : "hidden"}>{element}</div>;
// };
// const [spectodaSound] = useState(() => new SpectodaSound());
// const spectodaSound = new SpectodaSound();

export function useSpectodaSound(mic = null) {
  const [isRunning, setIsRunning] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [soundConnected, setSoundConnected] = useState(false);
  const [spectodaSound] = useState(() => new SpectodaSound());

  useEffect(() => {
    spectodaSound.on("soundEmit", () => {});
    return () => {
      spectodaSound.stop();
    };
  }, []);

  async function connect(mediaStream: MediaStream | "microphone" | undefined) {
    setIsReady(false);
    await spectodaSound.connect(mediaStream);
    setSoundConnected(true);
    setIsReady(true);
    return;
  }

  async function start() {
    setIsRunning(true);

    spectodaSound.start();
    return soundConnected;
  }

  function stop() {
    setIsRunning(false);

    spectodaSound.stop();
  }
  function toggle() {
    if (isRunning) {
      stop();
    } else {
      start();
    }
  }

  return { start, stop, toggle, connect, isRunning, isReady, spectodaSound };
}

export function useFWVersion() {
  const { isConnecting, connectionStatus, connect, disconnect } = useContext(TangleConnection);
  const [version, setVersion] = useState("unknown");
  const [fullVersion, setFullVersion] = useState("unknown");

  useEffect(() => {
    const versionEvent = tangleDevice.on("version", (version: string) => {
      setVersion(version);
    });
    return () => versionEvent();
  }, []);

  useEffect(() => {
    (async () => {
      if (connectionStatus === "connected") {
        try {
          const version = await tangleDevice.getFwVersion();
          console.log("FW version:", version);
          const [device_type, fw_version, build] = version.split("_");
          if (device_type === "DUMMY") {
            setVersion(fw_version);
            setFullVersion(version);
            // setVersion(process.env.REACT_APP_VERSION);
          } else if (device_type !== "NARA" && device_type !== "STICKS") {
            setVersion(fw_version);
            setFullVersion(version);

            // TODO make better error message
            // TOTRANSLATE
            // TangleMsgBox.alert(`Tento typ zařízení ${device_type} ovládat zatím neumím.`, t("Toto není NARA!"));
          } else {
            setVersion(fw_version);
            setFullVersion(version);
          }
        } catch {
          console.error("Failed to get FW version");
          setVersion("Failed to get FW version");
        }
      }
    })();
  }, [connectionStatus]);

  return { version, fullVersion };
}

export function getHexColor(colorStr: string) {
  const a = document.createElement("div");
  a.style.color = colorStr;
  //@ts-ignore
  const colors = window
    .getComputedStyle(document.body.appendChild(a))
    .color.match(/\d+/g)
    .map(function (a) {
      return parseInt(a, 10);
    });
  document.body.removeChild(a);
  return colors.length >= 3
    ? "#" + ((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2]).toString(16).substr(1)
    : false;
}
