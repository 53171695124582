// import esPkg from 'essentia.js';
import { setLoggingLevel } from "../tangle-js/Logging";
import { TangleDevice } from "../tangle-js/TangleDevice";
import { TangleMsgBox, i18webcomponents } from "../webcomponents/dialog-component";

const primaryColor = "#ff8c5c";

const tangleDevice = new TangleDevice("default");
// i18webcomponents.setLanguage("cs");
console.log(i18webcomponents.setResolvedLanguage("cs"));
tangleDevice.setLanguage("cs");

// console.log("AutoConnect")
// Matty key
// console.log("AutoConnect")
// Matty key
tangleDevice.assignOwnerSignature(localStorage.getItem("ownerSignature") || "a06cd5c4d5741b61fee69422f2590926");
tangleDevice.assignOwnerKey(localStorage.getItem("ownerKey") || "bfd39c89ccc2869f240508e9a0609420");
// // Lukas
// tangleDevice.assignOwnerSignature("65adda4326914576405c9e3a62f4904d");
// tangleDevice.assignOwnerKey("bfd39c89ccc2869f240508e9a0609420");
//@ts-ignore
window.tangleDevice = tangleDevice;
setLoggingLevel(0);
process.env.NODE_ENV === "development" && setLoggingLevel(4);
// process.env.NODE_ENV === "production" && tangleDevice.assignConnector("webbluetooth");
let url = new URL(location.href);
let params = new URLSearchParams(url.search);

if (params.get("demo")) {
  setTimeout(() => {
    tangleDevice.assignConnector("dummy");
  }, 300);
}

export { tangleDevice };

// const essentia = new esPkg.Essentia(esPkg.EssentiaWASM);
