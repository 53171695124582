import { AnimatePresence } from "framer-motion";
import Layout from "layout/Layout";
import Beyond from "pages/MP3";
import Distribution from "pages/Distribution";
import Kate from "pages/Kate";
import Responsible from "pages/Responsible";
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./layout/Header";
import Home from "./pages/Home";
import MP3 from "pages/MP3";
import MP3Default from "pages/MP3Default";

export default function Routing() {
  const location = useLocation();

  return (
    <Layout>
      <Header />
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/mp3" element={<MP3 />} />
          <Route path="/mp3/default" element={<MP3Default />} />
          {/* <Route path="kate" element={<Kate />} /> */}
          {/* <Route path="beyond" element={<Beyond />} />
          <Route path="distribution" element={<Distribution />} />
          <Route path="responsible" element={<Responsible />} /> */}
        </Routes>
      </AnimatePresence>
    </Layout>
  );
}
