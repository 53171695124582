import { useEffect, useRef, useState, useCallback } from "react";
import { tangleDevice } from "../lib/utils/communication";
import { ReactComponent as PlayIcon } from "../assets/icons/play.svg";
import { ReactComponent as PauseIcon } from "../assets/icons/pause.svg";
import { ReactComponent as PreviousIcon } from "../assets/icons/previous.svg";
import { ReactComponent as SkipIcon } from "../assets/icons/skip.svg";
import { AudioPlayerProvider, useAudioPlayer, useAudioPosition } from "react-use-audio-player";
import classes from "./PlayerComponent.module.css";
import { useSpectodaSound } from "lib/utils/utils";
import CSOB from "components/CSOB";

location.hash = "";

export function PlayerComponent({ url, name, microphoneSensitivity, emitMode }: any) {
  const [songIndex, setSongIndex] = useState(0);
  const songPositionRef = useRef();
  function handleSongSkip() {
    setSongIndex(songIndex + 1);
  }

  // @ts-ignore
  const getFormatedTime = time => {
    return (
      Math.floor(time / 60) +
      ":" +
      (Math.floor(time % 60) < 10 ? "0" : "") +
      // @ts-ignore

      Math.floor(time % 60 ? time % 60 : "00")
    );
  };

  const { percentComplete, position, seek, duration } = useAudioPosition({
    highRefreshRate: true,
  });

  useEffect(() => {
    if (songPositionRef.current) {
      // @ts-ignore
      songPositionRef.current.oninput = e => {
        // console.log("changing slider value", e.target.value);
        // goToPosition(e.target.valueAsNumber);
      };
    }
  }, [songPositionRef]);

  const { togglePlayPause, ready, loading, playing, player, pause, play } = useAudioPlayer({
    src: url,
    format: "mp3",
    autoplay: false,
    html5: true,
    loop: false,
    onplay: () => {
      tangleDevice.timeline.unpause();
      tangleDevice.syncTimeline();
      emitMode("reaction-mp3");
    },
    onend: () => {
      console.log("ended");
      // Hack na to aby show běžela po skončení show
      tangleDevice.timeline.unpause();
      tangleDevice.syncTimeline();
      stop();
    },
    onpause: () => {
      tangleDevice.timeline.pause();
      tangleDevice.syncTimeline();
      stop();
    },
  });

  const goToPosition = (percent: number) => {
    seek((percent / 1000) * duration);
    if (playing) {
      tangleDevice.timeline.pause();
    } else {
      tangleDevice.timeline.unpause();
    }
    tangleDevice.syncTimeline();
  };

  const { start, stop, spectodaSound, connect } = useSpectodaSound();

  useEffect(() => {
    const ref = spectodaSound.on("loudness", (value: number) => {
      // process.env.NODE_ENV !== "development" && tangleDevice.setDebugLevel(0);
      tangleDevice.emitPercentageEvent("sound", value);
      // console.log(value);
    });
    return () => ref();
  }, [spectodaSound]);

  useEffect(() => {
    spectodaSound.setSensitivity(microphoneSensitivity);
  }, [microphoneSensitivity]);

  useEffect(() => {
    let playerInterval: any;
    (async () => {
      playerInterval = setInterval(async () => {
        // @ts-ignore

        const audio = player?._sounds && player._sounds[0] && player._sounds[0]._node;
        if (audio && playing) {
          clearInterval(playerInterval);
          console.log(audio);

          // @ts-ignore
          window.audio = audio;
          await spectodaSound.connect(audio.captureStream());
          setTimeout(() => {
            spectodaSound.start();
          }, 100);
          start();
        } else {
          if (!audio) {
            console.log("audio not found");
          }
          stop();
        }
      }, 200);
    })();
    return () => clearInterval(playerInterval);
  }, [url, ready, playing]);

  // console.log(player);
  // @ts-ignore

  return (
    <div className={classes.infoWrap}>
      <div className={classes.songButtonWrap}>
        <button onClick={handleSongSkip} className={classes.songButton}></button>
        <button onClick={togglePlayPause} className={classes.songButton}>
          {playing ? <PauseIcon className={classes.songButtonIcon}></PauseIcon> : <PlayIcon className={classes.songButtonIcon}></PlayIcon>}
        </button>
        <button
          onClick={() => {
            handleSongSkip();
          }}
          className={classes.songButton}
        ></button>
      </div>
      <input
        type="range"
        min="0"
        max="1000"
        value={(position / duration) * 1000}
        //@ts-ignore
        ref={songPositionRef}
        onChange={e => goToPosition(e.target.valueAsNumber)}
        onTouchStart={() => pause()}
        // onTouchEnd={() => play()}
        className="w-full"
      />
      <div className={classes.timeWrap}>
        <p>{getFormatedTime(position)}</p>
        <p>{getFormatedTime(duration)}</p>
      </div>
    </div>
  );
}
